import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { ImBehance2 } from "react-icons/im";
import { FaInstagramSquare } from "react-icons/fa";




export const HeaderSocials = () => {
  return (
    <div className="header__socials">
    <a
      href="https://www.linkedin.com/in/nabil-wassim-bba45122b/" target="_blank"
      
    >
      <BsLinkedin/>
    </a>
    <a href="https://www.behance.net/nabilwassim" target="_blank">
      <ImBehance2/>
    </a>
    <a
      href="https://www.instagram.com/nabil_wassim1/" target="_blank"
    >
      <FaInstagramSquare/>
    </a>
  </div>
  )
}

export default HeaderSocials;
