import React from 'react'
import './services.css'
import {BsCheck} from 'react-icons/bs'
function Services() {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>
              UI/UX & Graphic Design
            </h3>
          </div>

          <ul className='service__list'>
            <li>
              <BsCheck className='service__list-icon'/>
              <p>I specialize in crafting seamless user experiences.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon'/>
              <p>My goal is to design interfaces that are both beautiful and functional.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon'/>
              <p>I use the latest design trends and techniques to create intuitive interfaces.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon'/>
              <p>I have an extensive experience in user-centered design.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon'/>
              <p> My focus is on delivering designs that are efficient, effective, and user-friendly.</p>
            </li>
            {/* <li>
              <BsCheck className='service__list-icon'/>
              <p>UI/UX designers often use wireframes, prototypes, and design systems to create and test their designs before launching the final product.</p>
            </li> */}
          </ul>
        </article>
        {/* END OF UI/UX */}

        <article className="service">
          <div className="service__head">
            <h3>
              Web & App Development
            </h3>
          </div>

          <ul className='service__list'>
            <li>
              <BsCheck className='service__list-icon'/>
              <p>I build custom websites that meet your unique business needs.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon'/>
              <p>I use the latest web development technologies and frameworks.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon'/>
              <p>I focus on creating websites that are fast, responsive, and optimized for search engines.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon'/>
              <p>My web development services are tailored to your specific requirements and goals.</p>
            </li>
            
            {/* <li>
              <BsCheck className='service__list-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li> */}
          </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}

        <article className="service">
          <div className="service__head">
            <h3>
              Content Creation
            </h3>
          </div>

          <ul className='service__list'>
            <li>
              <BsCheck className='service__list-icon'/>
              <p>I specialize in creating high-quality, engaging content for your business.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon'/>
              <p>Iam skilled in writing, editing, and producing a wide range of content types.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon'/>
              <p>I use a data-driven approach to content creation to ensure that it resonates with your target audience.</p>
            </li>
            <li>
              <BsCheck className='service__list-icon'/>
              <p>My content is designed to be informative, entertaining, and valuable to your audience.</p>
            </li>
            {/* <li>
              <BsCheck className='service__list-icon'/>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li> */}
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  )
}

export default Services