import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/IMG1.png";
import IMG2 from "../../assets/IMG2.png";
import IMG3 from "../../assets/IMG3.png";
import IMG4 from "../../assets/IMG4.png";
import IMG5 from "../../assets/IMG5.png";
import IMG6 from "../../assets/IMG6.png";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Safaqatek Shopping Coupons Application",
    github: "https://www.behance.net/gallery/156580319/Safaqatek-Shopping-Coupons-App-Website",
    demo: "https://www.behance.net/gallery/156580319/Safaqatek-Shopping-Coupons-App-Website",
  },
  {
    id: 2,
    image: IMG2,
    title: "HamdanyMedia Website For Social Media Management",
    github: "https://www.behance.net/gallery/168777165/HamdanyMedia-For-Social-Media-Management-Website-Logo",
    demo: "https://app.hamdanymedia.com/",
  },
  {
    id: 3,
    image: IMG3,
    title: "Mix Cheese Restaurant Social Media Designs",
    github: "https://www.behance.net/gallery/145095105/Social-Media-",
    demo: "https://www.instagram.com/mixcheese.sa/",
  },
  {
    id: 4,
    image: IMG4,
    title: "My Book-Book Online Store",
    github: "https://www.behance.net/gallery/148955097/My-Book-UX-UI-Case-Study",
    demo: "https://www.behance.net/gallery/148955097/My-Book-UX-UI-Case-Study",
  },
  {
    id: 5,
    image: IMG5,
    title: "TeethClinc - Professional Dental Consultations Website    ",
    github: "https://www.behance.net/gallery/191641975/TeethClinc-Professional-Dental-Consultations-Website",
    demo: "https://www.app.teethclinc.com/",
  },
  {
    id: 6,
    image: IMG6,
    title: "Dollars Application For Money Transfer Services",
    github: "https://www.behance.net/gallery/135289241/Dollars-Finance-App",
    demo: "https://www.behance.net/gallery/135289241/Dollars-Finance-App",
  },
];

function Portfolio() {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>

      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className="btn" target="_blank">
                  Behance
                </a>
                <a
                  href={demo}
                  className="btn btn-primary"
                  target="_blank"
                >
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
}

export default Portfolio;
