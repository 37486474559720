import React from "react";
import "./testimonials.css";
import AVTR1 from "../../assets/test1.jpg";
import AVTR2 from "../../assets/test2.jpg";
import AVTR3 from "../../assets/test3.jpg";
import AVTR4 from "../../assets/test4.jpg";

// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    avatar: AVTR1,
    name:"Fahd Mohamed",
    review:"I had the pleasure of working with Nabil Wassim on a recent website redesign project, and I have to say that I was blown away by his creativity and expertise. He took the time to really understand our brand and our audience, and he came up with a design that was both visually stunning and user-friendly. The development process was also smooth and efficient, and we were able to launch the new site on time and on budget.",
  },
  {
    avatar: AVTR2,
    name:"Mona Hussein",
    review:"I worked with nabil wassim to design and develop my company's website, and I couldn't be happier with the results. He was extremely professional and responsive throughout the entire process, and they delivered a beautiful and functional website that perfectly represents our brand. I would highly recommend nabil wassim to anyone looking for top-quality design and development services.",
  },
  {
    avatar: AVTR3,
    name:"John Michael",
    review:"I've worked with a lot of designers and developers over the years, but I have to say that Nabil Wassim stands out as one of the best. He has an incredible eye for design and a deep understanding of user experience, and he really go above and beyond to make sure that their clients are happy with the final product. I've recommended Nabil Wassim to several colleagues and friends, and I wouldn't hesitate to do so again in the future.",
  },
  {
    avatar: AVTR4,
    name:"Deema Karim",
    review:"Working with nabil was an absolute pleasure. he was professional, communicative, and incredibly talented, and they delivered a website that exceeded all of our expectations. They were able to take our vague ideas and turn them into a beautiful and functional website that perfectly represents our brand. I would highly recommend nabil to anyone looking for design and development services.",
  },
];

function Testimonials() {
  return (
    <section id="testimonials">
      <h5>Reviews From Clients</h5>
      <h2>Testimonials</h2>

      <Swiper
        className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, review }, index) => {
          return (
            <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar}/>

              </div>
              <h5 className="client__name">{name}</h5>
              <small className="client__review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
}

export default Testimonials;
